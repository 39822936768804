<template>
  <!-- card-hover-shadow  -->
  <base-card
    class="card-hover"
    :class="cardHoverShadow ? 'card-hover-shadow' : '' "
  >
    <v-img
      height="250"
      :src="contentImg"
    />

    <v-card-text class=" d-flex justify-content-between align-end">
      <div class="flex-grow-1 my-3">
        <h6 class="mb-0 grey--text text--darken-4">
          <router-link :to="LinkDauGia(itemData.AuctionID)">
            {{ SubStr(itemData.Title) }}
          </router-link>
        </h6>
        <div class="d-flex mb-1">
          <p>
            <v-btn
              color="blue"
              outlined
              x-small
            >
              <vue-countdown-timer
                :start-time="moment(itemData.StartTime).unix()"
                :end-time="moment(itemData.EndTime).unix()"
                :interval="1000"
                end-text="ĐÃ KẾT THÚC"
                day-txt="ngày"
                hour-txt="giờ"
                minutes-txt="phút"
                seconds-txt="giây"
              />
            </v-btn>
          </p>
        </div>
        <div class="d-flex">
          <h6 class="primary--text mr-2 mb-0">
            {{ new Intl.NumberFormat('jp-JP', { style: 'currency', currency: 'JPY' }).format(itemData.CurrentPrice) }}
          </h6>
          <h6 class="orange--text lighten-4 mb-0">
            {{ new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(itemData.CurrentPrice*System.TiGia) }}
          </h6>
        </div>
      </div>

      <div class="d-flex flex-column">
        <!-- <v-btn
                    class=" rounded "
                    outlined
                    fab
                    x-small
                    tile
                    color="primary"
                >
                    <v-icon>mdi-minus</v-icon>
                </v-btn>
                <div class="text-center">3</div> -->
        <v-btn
          text
          small
        >
          {{ itemData.Bids }} <v-icon
            right
            dark
          >
            mdi-gavel
          </v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </base-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    props: {
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      // eslint-disable-next-line vue/require-default-prop
      contentImg: String,
      // eslint-disable-next-line vue/require-default-prop
      itemData: {
        type: Object,
      },
    },

    data () {
      return {
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'System']),
    },
    methods: {
      LinkDauGia (ID) {
        return `/dau-gia/${ID}`
      },
      SubStr (data) {
        if (data.length > 40) {
          return data.substr(0, 40) + '...'
        }
        return data
      },
    },
  }
</script>
<style lang="scss" scoped>

  .card-hover {
    &:hover {
      .card-hover-icon {
        display: block;
      }
    }
    .card-hover-icon {
      display: none;
    }
  }

</style>
